import React, { useEffect, useState } from 'react';
import './ServicesPage.scss'
import Navbar from '../../components/Navbar/Navbar';
import LandingStrip from '../../components/LandingStrip/LandingStrip';
import TextSection from '../../components/TextSection/TextSection';
import LoadingPage from '../LoadingPage/LoadingPage';
import { Table } from '@mui/material';
import ConcreteFooter from '../../components/ConcreteFooter/ConcreteFooter';
import PriceListService from './../../../generic/services/PriceListService.js'
import LabelService from './../../../generic/services/LabelService.js'

const pl = new PriceListService();
const service = new LabelService();

function ServicesPage() { 
  const [data, setData] = useState([]);
  const [servis, setServis] = useState([]);
  const [prodej, setProdej] = useState([]);

  useEffect(()=>{
    pl.GetAll().then((data)=> {
        setData(data)
    })

    service.GetAll().then((data)=> {
        data.forEach((i) => {
            if (i.key == "prodej") {
                setProdej(i.value);
            } else if (i.key == "servis") {
                setServis(i.value);
            }
        })
    })
  },[])

  let prices = []
  let k = 0;
  data.forEach(i => {
    prices.push(
        <tr key={k}>
            <td>{i.name}</td>
            <td>{i.description}</td>
            <td>{i.price}</td>
        </tr>
    )
    k++;
  })

    return (
        <div className='servicesPageContainer'>
            <LoadingPage visible="false" />
            <Navbar />
            <LandingStrip
                bgImg={require('./../../../../images/servisAProdej.jpg')}
                heading="SERVIS A PRODEJ"
                headingColor="white"
                fontWeight="700"
                bgColor="black"
            />

            <div className='servisContainer'>
                <TextSection 
                    title="Servis vozidel"
                    title_color="black"
                    sub_title_color="black"
                    txt_color="black"
                    img={ require('./../../../../images/klice.png') }
                    alt="Designer Image"
                    img_align_mobile="bot"
                    img_align="right"
                    img_full_width_mobile>
                    <p>{ <div dangerouslySetInnerHTML={{ __html: servis }} /> }</p>

                    <h2 style={{fontWeight: "600"}}>Ceník servisních služeb</h2>

                    <div className='tableContainer'>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Činnost</th>
                                    <th></th>
                                    <th>Cena</th>
                                </tr>
                                {prices}
                            </tbody>
                        </Table>
                    </div>
                    
                </TextSection>
            </div> 

            <div className='servisContainer secondContainer'>
                <TextSection 
                    title="Prodej vozidel"
                    title_color="black"
                    sub_title_color="black"
                    txt_color="black"
                    img={ require('./../../../../images/lidi.png') }
                    alt="Designer Image"
                    img_align_mobile="bot"
                    img_align="left"
                    img_full_width_mobile>
                    <p>{ <div dangerouslySetInnerHTML={{ __html: prodej }} /> }</p>
                </TextSection>
            </div>

            <ConcreteFooter />
        </div>
    )
}

export default ServicesPage;