import React from 'react';
import './LandingStrip.scss'

function LandingStrip(props) {
    let styles = {
        backgroundColor: !! props.bgColor ? props.bgColor : '',
        backgroundImage: !! props.bgImg ? `url(${props.bgImg})` : 'white'
        
    }

    let headingStyles = {
        color: !! props.headingColor ? props.headingColor : "black",
        fontWeight: !! props.fontWeight ? props.fontWeight : '500' 
    }
    return (
        <div className="landingStripContainer" style={ styles }>
            <h1 style={headingStyles}>{props.heading}</h1>
        </div>
    )
}

export default LandingStrip;