import React, { useEffect, useState } from 'react';
import './GDPRPage.scss'
import Navbar from '../../components/Navbar/Navbar';
import LandingStrip from '../../components/LandingStrip/LandingStrip';
import TextSection from '../../components/TextSection/TextSection';
import LoadingPage from '../LoadingPage/LoadingPage';
import { Table } from '@mui/material';
import ConcreteFooter from '../../components/ConcreteFooter/ConcreteFooter';
import LabelService from './../../../generic/services/LabelService.js'
import Link from '@mui/material/Link';

const service = new LabelService();

function GDPRPage() { 
  const [data, setData] = useState([]);

  useEffect(()=>{
    service.GetAll().then((data)=> {
        data.forEach((i) => {
            if (i.key == "gdpr") {
                setData(i.value);
            }
        })
    })
  },[])

    return (
        <div className='gdprPageContainer'>
            {/* <LoadingPage visible="false" /> */}
            <Navbar />
            <LandingStrip
                bgImg={require('./../../../../images/servisAProdej.jpg')}
                heading="Pravidla pro nakládání s osobními údaji GDPR"
                headingColor="white"
                fontWeight="700"
                bgColor="black"
            />

            <div className='gdprContainer'>
                <p>{ <div dangerouslySetInnerHTML={{ __html: data }} /> }</p>
                <div className='links'>
                    <Link
                        href={require("./../../../../images/Souhlas_foto_prodej.pdf")}
                        download={"Souhlas_foto_prodej.pdf"}
                    >Udělení souhlasu se zpracováním osobních údajů foto a prodej
                    </Link>

                    <br />
                    
                    <Link
                        href={require("./../../../../images/Souhlas_servis.pdf")}
                        download={"Souhlas_servis.pdf"}
                    >Udělení souhlasu se zpracováním osobních údajů servis a kontakty
                    </Link>
                </div>
                
                
                
            </div>

            <ConcreteFooter />
        </div>
    )
}

export default GDPRPage;