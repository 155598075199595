import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

function ImageSlider(props) {
    let images = []

    for (let i = 0; i < props.images.length; i++) {
        images.push(
            <div key={i} 
                style={{ backgroundImage: `url("Images/${ props.images[i] }")`, height: "500px",
                            backgroundPosition: "center", backgroundSize: "cover", overflowY: "hidden" }}>
            </div>
        )
    }
    return (
        <Carousel showStatus={false} autoPlay={true} interval={5000} infiniteLoop={true}>
            {images}
        </Carousel>
    )
}

export default ImageSlider;