class UserService {
    constructor() {
        this.api_url = "api/identity"
    }

    async IsLoggedIn() {
        let response = await fetch(this.api_url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        let result = await response.json()

        return result;
    }

    async Login(username, password) {
        let item = {
            "UserName": username,
            "Password": password
        }
        let reponse = await fetch(this.api_url + "/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        });
        return reponse.ok;
    }

    async Logout() {

        let reponse = await fetch(this.api_url + "/logout", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return reponse.ok;
    }



}

export default UserService;