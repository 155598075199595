import React from 'react';
import Button from '@mui/material/Button';
import ContactService from '../../generic/services/ContactService';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, MenuItem, Modal, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';


const service = new ContactService();
function ContactEditor(props) {


    const [list, setList] = useState([])
    const [open, setOpen] = useState(false);
    const [Id, setId] = useState("");
    const [Name, setName] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [Subsection, setSubsection] = useState("");
    const [ServiceType, setServiceType] = useState(0);
    const [IsInFooter, setIsInFooter] = useState(false);




    useEffect(() => {
        service.GetAll().then(data => { setList(data) })
    }, [])

    function HandleChange(id) {
        let item = {
            "Id": Id,
            "Name": Name,
            "PhoneNumber": PhoneNumber,
            "MobileNumber": MobileNumber,
            "Email": Email,
            "Subsection": Subsection,
            "ServiceType": ServiceType,
            "IsInFooter": IsInFooter,
        }
        service.Update(item).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })

    }
    function HandleDelete(id) {
        service.Delete(id).then(() => {
            service.GetAll().then(data => { setList(data) });
        })
    }
    function HandleAddNew() {
        let item = {
            "Name": Name,
            "PhoneNumber": PhoneNumber,
            "MobileNumber": MobileNumber,
            "Email": Email,
            "Subsection": Subsection,
            "ServiceType": ServiceType,
            "IsInFooter": IsInFooter,
        }
        service.Create(item).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })
    }
    function HandleOpen() {
        setOpen(!open);
    }

    let input_style = { margin: "5px" }
    return <div style={props.style}>
        <Typography variant='h4'>Kontakty</Typography>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Jméno</TableCell>
                    <TableCell>Popisek</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.name}
                        </TableCell>
                        <TableCell>
                            {item.subsection}
                        </TableCell>
                        <TableCell>
                            {item.email}
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#2a60f5' }} onClick={() => {
                                setId(item.id)
                                setPhoneNumber(item.phoneNumber)
                                setMobileNumber(item.mobileNumber)
                                setEmail(item.email)
                                setSubsection(item.subsection)
                                setServiceType(item.serviceType);
                                setIsInFooter(item.isInFooter)
                                setName(item.name)
                                HandleOpen();
                            }}>Upravit</Button>
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#f52a2a' }} onClick={() => { HandleDelete(item.id) }}>Smazat</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Button variant="contained" style={{ marginTop: 15, backgroundColor: "#2a60f5" }} onClick={() => {
            setId(null)
            setPhoneNumber("")
            setMobileNumber("")
            setEmail("")
            setSubsection("")
            setServiceType(0);
            setIsInFooter(false)
            setName("")
            HandleOpen()
        }}>Přidat položku</Button>

        <Dialog open={open} onClose={HandleOpen}>
            <DialogTitle>{Id == null ? "Vytvořit nový" : "Editace"}</DialogTitle>
            <DialogContent style={{ width: "400px", paddingRight: 35 }} >
                <DialogContentText>
                </DialogContentText>
                <TextField fullWidth style={input_style} variant='standard' label="Jméno" value={Name} onChange={e => setName(e.target.value)} /> <br />
                <TextField fullWidth style={input_style} variant='standard' label="Pevná linka" value={PhoneNumber} onChange={e => setPhoneNumber(e.target.value)} /> <br />
                <TextField fullWidth style={input_style} variant='standard' label="Mobilní číslo" value={MobileNumber} onChange={e => setMobileNumber(e.target.value)} /> <br />
                <TextField type={"ema"} fullWidth style={input_style} variant='standard' label="Email" value={Email} onChange={e => setEmail(e.target.value)} /> <br />
                <TextField fullWidth style={input_style} variant='standard' label="Popisek" value={Subsection} onChange={e => setSubsection(e.target.value)} /> <br />

                <Select
                    style={{ ...input_style, ...{ marginTop: 20 } }}
                    fullWidth
                    value={ServiceType}
                    onChange={e => setServiceType(e.target.value)}
                    variant="standard"
                >
                    <MenuItem value={0}>Servis vozidel a prodej náhradních dílů</MenuItem>
                    <MenuItem value={1}>Prodej nových a ojetých vozů</MenuItem>
                </Select>

                <FormGroup>
                    <FormControlLabel label="Zobrazit v patičce" control={<Switch label="Zobrazit v patičce" checked={IsInFooter} onChange={e => setIsInFooter(e.target.checked)} />} />
                </FormGroup>


            </DialogContent>
            <DialogActions>
                <Button style={{ color: "#f52a2a" }} onClick={HandleOpen}>Zrušit</Button>
                <Button style={{ color: "#2a60f5" }} onClick={() => {
                    if (Id == null) HandleAddNew()
                    else HandleChange(Id)

                }}>Uložit</Button>
            </DialogActions>
        </Dialog>

    </div>;
}

export default ContactEditor;