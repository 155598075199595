import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import './VehicleCard.scss'
import {ReactComponent as Travel} from "./../../../../images/route-solid.svg";
import {ReactComponent as Price} from "./../../../../images/tag-solid.svg";

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

function VehicleCard(props) {
    return (
        <span className='vehicleCardContainer'>
            <Card sx={{ width: 300, height: 390 }}>
                <CardActionArea>
                <CardMedia
                    component="img"
                    height="240"
                    image={props.img}
                    alt={props.alt}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {props.heading}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ height: 60 }}>
                    {props.text}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "  right" }}>
                        <span style={{ display: "flex", alignItems: "center", marginRight: "20px"}}>
                            <Travel width="20px" style={{ marginRight: "10px" }} />
                            {numberWithSpaces(props.speedometer)} km
                        </span>

                        <span style={{ display: "flex", alignItems: "center"}}>
                            <Price width="17px" style={{ marginRight: "10px" }} />
                            {numberWithSpaces(props.price)} Kč
                        </span>
                    </div>
                    
                </CardContent>
                </CardActionArea>
            </Card>
        </span>
    );
}

export default VehicleCard;