class CarService {
    constructor() {
        this.api_url = window.location.origin + "/api/car"
    }

    async GetAll() {
        let reponse = await fetch(this.api_url);
        let data = await reponse.json();
        return data;
    }

    async GetById(id) {
        let reponse = await fetch(this.api_url + "/" + id);
        let data = await reponse.json();
        return data;
    }

    async Create(item) {
        let reponse = await fetch(this.api_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(item)
        });
        let data = await reponse.json();
        return data;
    }

    async Update(item) {
        let reponse = await fetch(this.api_url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(item)
        });
        return reponse.ok;

    }
    async Delete(id) {
        let reponse = await fetch(this.api_url + "/" + id, {
            method: 'DELETE',
        });
        return reponse.ok;
    }

    async GetImages(id) {
        let reponse = await fetch(this.api_url + "/GetAllImages/" + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        let data = await reponse.json();
        return data;
    }

    async UploadImage(car_id, form_file) {
        let f = new FormData();
        // let f = new FormData();
        f.append("file", form_file)
        let reponse = await fetch(this.api_url + "/addimage/" + car_id, {
            method: 'POST',
            body: f
        });
        if (!reponse.ok) {
            return false;
        }

        return true;
    }

    async DeleteImage(image_id) {
        let reponse = await fetch(this.api_url + "/RemoveImage/" + image_id, {
            method: 'DELETE',
        });
        return reponse.ok;
    }

}

export default CarService;