class OpenningHoursService {
    constructor() {
        this.api_url = window.location.origin + "/api/openinghours"
    }
    ConvertDateTimeToTime(str) {
        if (str == null) return "";
        let a = new Date(str)
        return a.toLocaleTimeString('cs-CZ', { hour: "2-digit", minute: "2-digit" })
    }

    GetDayFromDayIndex(day_index) {
        switch (day_index) {
            case 0:
                return "Pondělí"
            case 1:
                return "Úterý"
            case 2:
                return "Středa"
            case 3:
                return "Čtvrtek"
            case 4:
                return "Pátek"
            case 5:
                return "Sobota"
            case 6:
                return "Neděle"

            default:
                return "error"
                break;
        }
    }
    async GetAll() {
        let reponse = await fetch(this.api_url);
        let data = await reponse.json();
        for (let i = 0; i < data.length; i++) {
            data[i]["shiftStart"] = this.ConvertDateTimeToTime(data[i]["shiftStart"]);
            data[i]["shiftEnd"] = this.ConvertDateTimeToTime(data[i]["shiftEnd"]);
            data[i]["lunchStart"] = this.ConvertDateTimeToTime(data[i]["lunchStart"]);
            data[i]["lunchEnd"] = this.ConvertDateTimeToTime(data[i]["lunchEnd"]);

        }

        return data;
        return [
            { id: 1, name: "test1", price: 2 },
            { id: 2, name: "test2", price: 34 }
        ]
    }

    async Create(item) {
        let reponse = await fetch(this.api_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(item)
        });

        return reponse.ok;
        if (!reponse.ok) {
            return false;
        }
        let data = await reponse.json();
        return data;
    }

    async Update(item) {

        let response = await fetch(this.api_url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(item)
        });
        return response.ok;

    }
    async Delete(id) {
        let reponse = await fetch(this.api_url + "/" + id, {
            method: 'DELETE',
        });
        return reponse.ok;
    }

}

export default OpenningHoursService;