class BannerService {
    constructor() {
        this.api_url = window.location.origin + "/api/banner"
    }

    async GetAll() {
        let reponse = await fetch(this.api_url);
        let data = await reponse.json();
        return data;
    }

    async Create(item, file) {
        let f = new FormData();
        // let f = new FormData();
        f.append("Visibility", item.visibility)
        f.append("Photo", file)
        f.append("Url", item.url)
        f.append("Location", item.location)

        let reponse = await fetch(this.api_url, {
            method: 'POST',
            body: f
        });
        if (!reponse.ok) {
            return false;
        }
    }

    async Update(item) {
        let f = new FormData();
        // let f = new FormData();
        f.append("Id", item.id)
        f.append("Visibility", item.visibility)
        f.append("Photo", null)
        f.append("Url", item.url)
        f.append("Location", item.location)

        let reponse = await fetch(this.api_url, {
            method: 'PUT',
            body: f
        });
        if (!reponse.ok) {
            return false;
        }
    }

    async GetImage(image_id) {
        let reponse = await fetch("api/photo/" + image_id);
        let data = await reponse.json();
        return data;
    }
    async Delete(id) {
        let reponse = await fetch(this.api_url + "/" + id, {
            method: 'DELETE',
        });
        return reponse.ok;
    }

}

export default BannerService;