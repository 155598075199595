import React, {useState} from 'react';
import './LandingSite.scss'

function LandingSite() {
    return (
        <div className='landingSiteContainer'>
            <h1>CL JUNIOR AUTO<br/>BOSKOVICE</h1>
        </div>
    );
}

export default LandingSite;