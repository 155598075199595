import React from 'react';
import Button from '@mui/material/Button';
import PriceListEditor from '../../components/PriceListEditor';
import { AppBar, Card, CardContent, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import CMSDrawer from '../../components/CMSDrawer';
import { useState } from 'react';
import CarListEditor from '../../components/CarList';
import BannerEditor from '../../components/BannerEditor';
import './GeneralPage.scss';
import { useEffect } from 'react';
import UserService from '../../../generic/services/UserService';
import OpenningHoursEditor from '../../components/OpenningHours';
import ContactEditor from '../../components/Contacts';
import LabelEditor from '../../components/LabelEditor';

const service = new UserService();
function GeneralPage() {
  const [password, setPassword] = useState("")
  const [username, setUserName] = useState("")

  const [open_drawer, setOpenDrawer] = useState(true)
  const [logged_in, setLogged] = useState(false)

  const [goodLogin, setGoodLogin] = useState(true)

  useEffect(() => {
    const logged = localStorage.getItem('logged');
    if (logged == null || logged === false.toString()) {
      setLogged(false);
    }
    else {
      setLogged(true);
    }
    service.IsLoggedIn().then((result)=>{
      if (result){
        localStorage.setItem("logged", true)
        setLogged(true);
      }
      else{
        localStorage.setItem("logged", false);
        setLogged(false);
      }
    })
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      Loggin();
    }
  }

  function ToggleDrawer() {
    setOpenDrawer(!open_drawer)
  }

  function Loggin() {
    service.Login(username,password).then((res)=>{
      if (res){
        localStorage.setItem("logged", true)
        setLogged(true);
      }
      else{
        setGoodLogin(false)
      }
    });
  }
  if (logged_in === false) {
    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: "gray" }}>
      <Card>
        <CardContent>
          <h4>Redakční systém</h4>
          <TextField name='username' variant='standard' onKeyDown={handleKeyDown} value={username} onChange={(e) => setUserName(e.target.value)} label="Uživatelské jméno"/> <br />

          <TextField name='password' variant='standard' onKeyDown={handleKeyDown} value={password} onChange={(e) => setPassword(e.target.value)} label="Heslo" type={"password"} /> <br />
          <Button name="loggin" onClick={() => { Loggin() }}>Přihlásit se</Button>
          {goodLogin ?  "" : "Bad password :(" }
        </CardContent>
      </Card>
    </div>
  }

  const editor_styles = { background: "white", boxShadow: "-1px 4px 18px 0px rgba(0,0,0,0.15)", margin: "20px", padding: "25px", borderRadius: "5px", width: "100%", maxWidth: "800px" }
  return <div style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
    <CMSDrawer open={open_drawer} toggle={() => { ToggleDrawer() }} />

    <div className='GeneralPage'>
      <Stack spacing={5} justifyContent="center"
        alignItems="center">

        <PriceListEditor style={editor_styles} />
        <CarListEditor style={editor_styles} />
        <BannerEditor style={editor_styles} />
        <ContactEditor style={editor_styles} />
        <OpenningHoursEditor style={editor_styles}/>
        <LabelEditor style={editor_styles}/>
      </Stack>
    </div>
  </div >;
}

export default GeneralPage;