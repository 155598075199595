class LabelService {
    constructor() {
        this.api_url = window.location.origin + "/api/text"
    }

    async GetAll() {
        let reponse = await fetch(this.api_url);
        let data = await reponse.json();
        return data;
        return [
            { id: 1, name: "test1", price: 2 },
            { id: 2, name: "test2", price: 34 }
        ]
    }

    async Create(item) {
        let reponse = await fetch(this.api_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(item)
        });
        return reponse.ok;
        if (!reponse.ok) {
            return false;
        }
        let data = await reponse.json();
        return data;
    }

    async Update(item) {
        let response = await fetch(this.api_url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(item)
        });
        return response.ok;

    }
    async Delete(id) {
        let reponse = await fetch(this.api_url + "/" + id, {
            method: 'DELETE',
        });
        return reponse.ok;
    }

    async GetByKey(key){
        let reponse = await fetch(this.api_url+"/key/" + key);
        let data = await reponse.json();
        return data;
    }

}

export default LabelService;