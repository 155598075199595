import React, { useEffect, useState } from 'react';
import './ContactPage.scss';
import Navbar from '../../components/Navbar/Navbar';
import LandingStrip from '../../components/LandingStrip/LandingStrip';
import LoadingPage from '../LoadingPage/LoadingPage';
import ConcreteFooter from '../../components/ConcreteFooter/ConcreteFooter';
import ContactService from './../../../generic/services/ContactService.js'

const cs = new ContactService();

function ContactPage() {
    
    const [data, setData] = useState([]);
    let images = {
        osoba: require('./../../../../images/osoba.png'),
        telefon: require('./../../../../images/telefon.png'),
        mobil: require('./../../../../images/mobil.png'),
        mail: require('./../../../../images/mail.png'),
    }

    useEffect(()=>{
        cs.GetAll().then((data)=> {
            setData(data)
        })}
        ,[])

    let elements = []
    let row1 = [];
    let row2 = [];
    let e;
    let i = 0;
    data.forEach(l => {
        e = <div className='personWrapper' key={++i}>
                { !! l.subsection ? <h3  style={{textTransform: "uppercase", fontSize: "1.2em", fontWeight: 700}}>{l.subsection}</h3> : ''}
                { !! l.name ? <span className='infoRow'><img loading='lazy' src={images.osoba} />{l.name}</span> : ''}
                { !! l.phoneNumber ? <span className='infoRow'><img loading='lazy' src={images.telefon} />{l.phoneNumber}</span> : ''}
                { !! l.mobileNumber ? <span className='infoRow phone'><img loading='lazy' src={images.mobil} />{l.mobileNumber}</span> : ''}
                { !! l.email ? <span className='infoRow mail'><img loading='lazy' src={images.mail} />{l.email}</span> : ''}
            </div>

        if (l.serviceType == 0) {
            row1.push(e)
        } else if (l.serviceType == 1) {
            row2.push(e)
        }
    })


    
    if (row2.length > 0) {
        elements.push(  
            <div className='categoryWrapper' key={++i}>
                <h2>Prodej nových a ojetých vozů</h2>
                <div className="personListWrapper">
                    {row2}   
                </div>
            </div>
        )
    }

    if (row1.length > 0) {
        elements.push(  
            <div className='categoryWrapper' key={++i}>
                <h2>Servis vozidel a prodej náhradních dílů</h2>
                <div className="personListWrapper">
                    {row1}   
                </div>
            </div>
        )
    }


    return (
        <div className="contactPageContainer">
            <Navbar />

            <LandingStrip
                bgImg={require('./../../../../images/contact.jpg')}
                heading="KONTAKT"
                headingColor="white"
                fontWeight="700"
                bgColor="black"
            />

            <div className='contactMapContainer'>
                <div className="contactWrapper">
                    {elements}
                </div>

                <div className='divider'></div>

                <div className="contactWrapper mapWrapper">
                    
                <h2>Kde nás najdete?</h2>
                    <div className='textPart'>
                        <div className='mapIconWrapper'>
                            <img loading='lazy' src={require('./../../../../images/location.png')} alt="" />
                        </div>
                        <div className='textWrapper'>
                            <p>
                            CL Junior auto Boskovice s.r.o. <br />
                            nám. 9. května 2a <br />
                            680 01 Boskovice
                            </p>
                        </div>
                    </div>

                    <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=CL%20Junior%20auto%20Boskovice%20s.r.o.%20n%C3%A1m.%209.%20kv%C4%9Btna%202a%20680%2001%20Boskovice&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                </div>
            </div>

            <div className='ICContainer'>
                <p><strong>IČ</strong> 63481251</p>
                <p><strong>DIČ</strong> CZ63481251</p>
                <p>Společnost je zapsána u Krajského soudu v Brně zn. C 20907.  </p>
            </div>

            <ConcreteFooter />
        </div>
    )
}

export default ContactPage;