import React, { useEffect, useState } from 'react';
import LandingSite from '../../components/LandingSite/LandingSite';
import Navbar from '../../components/Navbar/Navbar';
import Tile from './../../components/Tile/Tile'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import VehicleCard from './../../components/VehicleCard/VehicleCard';
import './HomePage.scss'
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import Footer from './../../components/Footer/Footer';
import {ReactComponent as FBLogo} from "./../../../../images/square-facebook.svg";
import BannerService from '../../../generic/services/BannerService';
import CarService from './../../../generic/services/CarService';
import { Link } from 'react-router-dom';
import ConcreteFooter from '../../components/ConcreteFooter/ConcreteFooter';
import LoadingPage from '../LoadingPage/LoadingPage';
import { useNavigate } from "react-router-dom";

const banner = new BannerService()
const vehService = new CarService()

function HomePage(props) {
  const [banners, setBanners] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [loadingPageView, setLoadingPageView] = useState(0);
  const [gotBanners, setGotBanners] = useState(false);
  const [gotVehicles, setGotVehicles] = useState(false);

  const [showNew, setShowNew] = useState(props.showNew);
  const [showOld, setShowOld] = useState(props.showOld);

  useEffect(()=>{
    setLoadingPageView(0)
    banner.GetAll().then((data)=>{
        setBanners(data)
        setGotBanners(true)
    })

    vehService.GetAll().then((data)=> {
      setVehicles(data)
      setGotVehicles(true)
    })

  }
  ,[])

  window.history.replaceState(null, "", "/")


  // generate vehicle cards
  let vehicleCards = []
  for (let i = 0; i < vehicles.length; i++) {
    if ((vehicles[i].isNewCar && showNew && vehicles[i].visibility) || (!vehicles[i].isNewCar && showOld && vehicles[i].visibility)) {
      vehicleCards.push(
        <a key={i} href={"/vehicle_detail/" + vehicles[i].id} style={{ textDecoration: "none", margin: 15 }}>
          <VehicleCard 
            alt="alt text" 
            img={vehicles[i].mainPhoto ? "Images/" + vehicles[i].mainPhoto.path : require('./../../../../images/nocar.png')} 
            heading={vehicles[i].brandModel} 
            text={vehicles[i].description}
            speedometer={vehicles[i].speedometer}
            price={vehicles[i].price}
             />
        </a>
      )
    }
  }


  let tiles = []
  let carouselImages = []

  for (let i = 0; i < banners.length; i++) {
    if (banners[i].location == 0) {
      carouselImages.push(banners[i].photo.path)
    } else if (banners[i].location == 1) {
      tiles.push(
        <Tile key={banners[i].id} width={100} height={100} img={"Images/" + banners[i].photo.path} />
      )
    }
  }
  
  return (
        <div className='homePageContainer'>
            <LoadingPage visible={ gotVehicles && gotBanners ? true : false} />
            {/* <LoadingPage visible={ true } /> */}

            <Navbar mainPage={true} setShowNew={setShowNew} setShowOld={setShowOld} />
            <LandingSite />

            <div className='imagesContainer' > 
              <div className='tilesContainer' style={{ visibility: tiles.length == 0 ? "hidden" : "visible", display: tiles.length == 0 ? "none" : "flex" }} >
                {tiles}
              </div>
            </div>

            <a href="https://www.facebook.com/profile.php?id=100083332481237" target="_blank">
              <div className='fbContact'>
                <p>Sledujte nás na Facebooku</p>
                <span><FBLogo fill='white' /></span>
              </div>
            </a>
            
            
            <div className='vehicleOffersContainer' id="offer">
                <div className='textContainer'>
                    <h2>Nabídka vozů</h2>
                </div>
                <div className="formGroup">
                  <FormControlLabel onClick={() => {setShowOld(!showOld)}} control={<Checkbox checked={showOld} />} label="Ojeté vozy" />
                  <FormControlLabel onClick={() => {setShowNew(!showNew)}}  control={<Checkbox checked={showNew} />} label="Nové vozy" />
                </div>
    
                <div className='vehiclesContainer' >
                    { vehicleCards.length == 0 ? <p style={{ textAlign: "center", width: "100%" }}>V současné době v nabídce žádná vozidla nemáme.</p> : vehicleCards}
                </div>
            </div>

            <ConcreteFooter mainPage={true} setShowNew={setShowNew} setShowOld={setShowOld}  />

        </div>    
    ); 
        
}

export default HomePage;