import React, { useEffect, useState } from 'react';
import './Footer.scss';
import OpenningHoursService from './../../../generic/services/OpenningHoursService.js'
import { Table } from '@mui/material';

const oh = new OpenningHoursService();
const Footer = (props) => {
  const [openHours, setOpenHours] = useState([]);

  useEffect(()=>{
    oh.GetAll().then((data) => {
      setOpenHours(data)
    })
    
  },[])

  let bgStyles = {
    'background': props.bg ? props.bg : "#222",
    'visibility': props.loading ? "hidden": "visible"
  }

  let categoryStyles = {
    'color': props.category_txt_color ? props.category_txt_color : 'white'
  }

  let subcategoryStyles = {
    'color': props.subcategory_txt_color ? props.subcategory_txt_color : 'white'
  }

  let categories = [];
  let i = 0;
  props.categories.forEach(category => {
    categories.push(
      // One section
      <section className="section" key={++i}>
        <div className="heading-container">
          {/* Heading of the section */}
          <h2 className="section_title" style={categoryStyles}>{category.text}</h2>
        </div>
        
        {/* Sub-sections */}
        <ul className="list_of_subcategories">
          {category.sub_categories.map(sub_category => 
            <li key={++i} style={sub_category.styles ? sub_category.styles : null}><a target={sub_category.new ? "_blank" : "_self"} className="subcategory" style={subcategoryStyles} onClick={() => {
              if (sub_category.mainPage == true && sub_category.newCars == true) {
                sub_category.setShowNew(true);
                sub_category.setShowOld(false);
              } else if (sub_category.mainPage == true && sub_category.oldCars == true) {
                sub_category.setShowNew(false);
                sub_category.setShowOld(true);
              }
            }} href={ sub_category.mainPage == true ? "#offer" : sub_category.link }>{sub_category.text}</a></li>
          )}
        </ul>
      </section>
    )
  })

  let tableShop = []
  let tableServices = []
  let j = 0;
  let element;
  openHours.forEach(d => {
    element = [<tr key={j}>
                <td style={{paddingRight: 25}}>{d.dayInWeek}</td>
                <td>{d.shiftStart} – {d.shiftEnd}</td>
              </tr>]
    if (d.lunchStart && d.lunchEnd) {
      element.push(<tr key={++j}><td style={{paddingRight: 20}}>Polední přestávka</td><td>{d.lunchStart} – {d.lunchEnd}</td></tr>)
    }
    
    if (d.serviceType == 1) {
      tableShop.push(element) 
    } else if (d.serviceType == 0) {
      tableServices.push(element)
    }
    j++;
  })

  let content = <Table>
    <tbody>
      <tr>
        <th colSpan={2}><h3>Prodejna</h3></th>
      </tr>
      {tableShop}
      <tr>
        <th colSpan={2}><h3 style={{marginTop: 6}}>Servis a prodej ND</h3></th>
      </tr>
      {tableServices}
    </tbody>
  </Table>

  return (
    <div className="footer_container" style={bgStyles}>
      <div className='categoriesWrapper'>
      {categories}

      <section className='section openHours'>
        <div className="heading-container">
          <h2 className="section_title" style={categoryStyles}>Otevírací doba</h2>
        </div>
        { content }
      </section>
    </div>

    <a href="https://www.citroen.cz/" target="_blank">
      <img loading='lazy' src={require('./../../../../images/logo2.png')} alt="" />
    </a>

  </div>
  );
}

export default Footer;