import React, { useEffect, useState } from 'react';
import PhotoAlbum from "react-photo-album";
import Dialog from '@mui/material/Dialog';
import './PhotoGallery.scss'
import BannerService from './../../../generic/services/BannerService'
import { getImageSize } from 'react-image-size';
import {ReactComponent as Left} from "./../../../../images/left.svg";
import {ReactComponent as Right} from "./../../../../images/right.svg";
import LoadingPage from './../../pages/LoadingPage/LoadingPage'

const banner = new BannerService()

async function GenerateImages(photos) {
   let p = []
   
      for (let i = 0; i < photos.length; i++) {
         if (photos[i].location == 0) {
             let data = await getImageSize("Images/" + photos[i].photo.path);
             
             p.push({
                 src: "Images/" + photos[i].photo.path,
                 width: data.width,
                 height: data.height,
             })
         }
        }
   return p;
}

function SimpleDialog(props) {

   if (props.activeIndex >= props.images.length) {
      return;
   }

   const handleClose = () => {
     props.onClose();
   };

   return (
      <Dialog onClose={handleClose} open={props.open} fullWidth maxWidth="lg">
         <div className='dialog' style={{
            backgroundColor: "black",
            backgroundImage: `url("${ props.images[props.activeIndex].src }")`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat" 
         }}>

            <div style={{position: "absolute", display: "flex", justifyContent: "space-between", height: "100%", width: "100%", zIndex: 15}}>
               <div className='moveContainer' onClick={() => props.handler(props.activeIndex - 1)}>
                  <Left className='icon' style={{ width: 13 }} fill="white" />
               </div>
               <div className='moveContainer' onClick={() => props.handler(props.activeIndex + 1)}>
                  <Right className='icon' style={{ width: 13 }} />
               </div>
            </div>
         </div>
      </Dialog>
   );
}

function SimpleGallery () {
   
   const [open, setOpen] = React.useState(false);
   const [activeIndex, setActiveIndex] = React.useState(0 );
   const [banners, setBanners] = useState([]);
   const [images, setImages] = React.useState([]);
   const [loading, setLoading] = React.useState(true);


  const handleClickOpen = () => {
   setOpen(true);
   };

 const handleClose = (value) => {
   setOpen(false);
   };

   
   useEffect(()=>{
         setLoading(true)
        banner.GetAll().then((data)=>{
            setBanners(data)
            GenerateImages(data)
              .then((imgs) => {
               setImages(imgs)
              })
            setLoading(false)
        })
      },[])


   const handleIndex = (value) => {
      if (value < images.length && value >= 0) setActiveIndex(value);
   }

   return (
      <div className='galleryContainer'>
         <PhotoAlbum layout="rows" spacing="5" photos={images} onClick={({index}) => {
            handleClickOpen();
            setActiveIndex(index);}} />

               <SimpleDialog
               open={open}
               onClose={handleClose}
               activeIndex={activeIndex}
               fullScreen
               images={images}
               handler={handleIndex}/>
      </div>
   );
}

export default SimpleGallery;