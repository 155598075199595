import React, { useState } from 'react';
import './ContactForm.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ContactService from '../../../generic/services/ContactService';

let cs = new ContactService();

function ContactForm(props) {
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [subject, setSubject] = useState(props.subject_placeholder);
    const [text, setText] = useState("");
    const [success, setSuccess] = useState(false);
    const [visible, setVisible] = useState(false);

    const OnSubmit = () => {
        cs.SendEmail(name, address, subject, text)
            .then((result) => {
                if (result) {
                    setSuccess(true)
                } else {
                    setSuccess(false)
                }
                setVisible(true)
            })
    }

    let styles = {
        backgroundColor: !! props.bg_color ? props.bg_color : "white",
        border: !! props.border ? props.border : "",
        boxShadow: !! props.shadow ? "rgba(0, 0, 0, 0.06) 0px 54px 55px, rgba(0, 0, 0, 0.06) 0px -12px 30px, rgba(0, 0, 0, 0.06) 0px 4px 6px, rgba(0, 0, 0, 0.00) 0px 12px 13px, rgba(0, 0, 0, 0.0) 0px -3px 5px" : ""
    }

    return (
            <form action="" className='form' style={styles}>
                <TextField 
                    className='input'
                    color="primary" 
                    id="standard-basic" 
                    label="Jméno a přijmení" 
                    variant="standard" 
                    value={name} 
                    onChange={data=>{setName(data.target.value)}} />

                <TextField 
                    className='input'
                    color="primary" 
                    id="standard-basic" 
                    label="Vaše e-mailová adresa" 
                    variant="standard" 
                    value={address} 
                    onChange={data=>{setAddress(data.target.value)}} />

                <TextField 
                    className='input'
                    color="primary" 
                    id="standard-basic" 
                    label="Předmět" 
                    variant="standard" 
                    value={subject} 
                    onChange={data=>{setSubject(data.target.value)}} />

                <TextField
                    className='input-multiline'
                    label="Zpráva"
                    multiline
                    rows={7}
                    value={text}
                    onChange={data=>{setText(data.target.value)}}
                />

                <p style={{ marginTop: 15, marginBottom: 0 }}><i>Odesláním souhlasíte se zpracováním osobních údajů.</i></p>

                <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 15 }}>
                    <Button 
                        className='form-button'
                        style={{ marginTop: 0 }}
                        variant="outlined" 
                        onClick={OnSubmit}>
                            Odeslat
                    </Button>

                    { visible ? <span>{ success ? <p style={{ margin: 0, color: "#25c235" }}>E-mail odeslán</p> : <p style={{ margin: 0, color: "#c22525" }}>Chyba při odesílání e-mailu</p> }</span> : "" }
                </span>
            </form>        
    )
}

export default ContactForm;