import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './VehicleDetail.scss';
import { Table } from '@mui/material';
import SimpleGallery from '../../components/SimpleGallery/SimpleGallery';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import { useParams } from 'react-router-dom';
import CarService from './../../../generic/services/CarService';
import { getImageSize } from 'react-image-size';
import ConcreteFooter from '../../components/ConcreteFooter/ConcreteFooter';
import LoadingPage from '../LoadingPage/LoadingPage';

const vehService = new CarService()
function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function VehicleDetail(props) {
    const [car, setCar] = useState({})
    const [loading, setLoading] = useState(true)
    const params = useParams();
    let carID = params.id

    useEffect(()=>{
        setLoading(true)
        vehService.GetById(carID)
            .then((data) => {
                setCar(data)
                setLoading(false)
            })
        }
    ,[])   

    

    let carVisibleContent = <div>
    <div className='carDetailContainer' style={{paddingBottom: 40}}>
        <h1>{ car.brandModel }</h1>
        
        <div className="contentContainer">
            <div className='tableContainer'>
                <h2>Informace o vozidle</h2>
                <p>{ car.description ? car.description : "" }</p>
                <p>{ car.descriptionNote ? <div dangerouslySetInnerHTML={{ __html: car.descriptionNote }} /> : "" }</p>
                <Table style={{marginTop: 25}}>
                    <tbody>
                        <tr>
                            <td>Druh</td>
                            <td>{car.category == 0 ? "Osobní" : "Užitkové"}</td>
                        </tr>
                        { car.state ? <tr>
                            <td>Stav</td>
                            <td>{car.state}</td>
                        </tr> : "" }
                        
                        { car.speedometer ? <tr>
                            <td>Tachometr</td>
                            <td>{numberWithSpaces(car.speedometer)} km</td>
                        </tr> : "" }
                        
                        { car.typeCar ? <tr>
                            <td>Karoserie</td>
                            <td>{car.typeCar}</td>
                        </tr> : "" }
                        
                        { car.color ? <tr>
                            <td>Barva</td>
                            <td>{ car.color }</td>
                        </tr> : "" }
                        
                        { car.doorCount ? <tr>
                            <td>Počet dveří</td>
                            <td>{ car.doorCount }</td>
                        </tr> : "" }
                        
                        { car.seatCount ? <tr>
                            <td>Počet sedadel</td>
                            <td>{ car.seatCount }</td>
                        </tr> : "" }

                        { car.fuel ? <tr>
                            <td>Palivo</td>
                            <td>{ car.fuel }</td>
                        </tr> : "" }

                        { car.power ? <tr>
                            <td>Výkon</td>
                            <td>{ car.power } kW</td>
                        </tr> : "" }

                        { car.zeroToHundered ? <tr>
                            <td>Z 0 na 100</td>
                            <td>{ car.zeroToHundered } s</td>
                        </tr> : "" }

                        { car.maxSpeed ? <tr>
                            <td>Maximální rychlost</td>
                            <td>{ car.maxSpeed } km/h</td>
                        </tr> : "" }
                        
                        { car.fuelConsumption ? <tr>
                            <td>Spotřeba</td>
                            <td>{ car.fuelConsumption } l/100km</td>
                        </tr> : "" }                   
                        
                        { car.upholstery ? <tr>
                            <td>Čalounění</td>
                            <td>{ car.upholstery } kg</td>
                        </tr> : "" }
                        
                        { car.torque ? <tr>
                            <td>Max. točivý moment</td>
                            <td>{ car.torque } Nm</td>
                        </tr> : "" }
                        
                        { car.transmission ? <tr>
                            <td>Převodovka</td>
                            <td>{ car.transmission }</td>
                        </tr> : "" }
                        
                        { car.emission ? <tr>
                            <td>Emise</td>
                            <td>{ car.emission } CO2 (g/km)</td>
                        </tr> : "" }

                        { car.engineContent ? <tr>
                            <td>Objem motoru</td>
                            <td>{ car.engineContent } l</td>
                        </tr> : "" }

                        { car.trunkVolume ? <tr>
                            <td>Objem zavazadlového prostoru</td>
                            <td>{ car.trunkVolume } l</td>
                        </tr> : "" }

                        { car.tankVolume ? <tr>
                            <td>Objem nádrže</td>
                            <td>{ car.tankVolume } l</td>
                        </tr> : "" }

                        { car.strokeVolume ? <tr>
                            <td>Zdvihový objem</td>
                            <td>{ car.strokeVolume } cm3</td>
                        </tr> : "" }

                        { car.length ? <tr>
                            <td>Délka</td>
                            <td>{ car.length } m</td>
                        </tr> : "" }

                        { car.height ? <tr>
                            <td>Výška</td>
                            <td>{ car.height } m</td>
                        </tr> : "" }

                        { car.width ? <tr>
                            <td>Šířka</td>
                            <td>{ car.width } m</td>
                        </tr> : "" }

                        { car.weight ? <tr>
                            <td>Váha</td>
                            <td>{ car.weight } kg</td>
                        </tr> : "" }

                        { car.ece ? <tr>
                            <td>Městský provoz ECE</td>
                            <td>{ car.ece }</td>
                        </tr> : "" }

                        { car.eudc ? <tr>
                            <td>Mimoměstský provoz EUDC</td>
                            <td>{ car.eudc }</td>
                        </tr> : "" }

                        { car.lcdv ? <tr>
                            <td>eLCDV</td>
                            <td>{ car.lcdv } m</td>
                        </tr> : "" }

                        { car.mixxedTraffic ? <tr>
                            <td>Smíšený provoz</td>
                            <td>{ car.mixxedTraffic }</td>
                        </tr> : "" }

                    </tbody>
                </Table>

            
                <div className='priceContainer'>
                    { car.price ? <div>
                        <p className='priceLabel'>Cena s DPH</p>
                        <p className='oldPrice'>{ numberWithSpaces(car.price) } Kč</p>
                    </div> : "" }
                    
                    { car.discountPrice ? <div>
                        <p className='priceLabel'>Cena bez DPH</p>
                        <p className='newPrice'>{ numberWithSpaces(car.discountPrice) } Kč</p>
                    </div> : "" }
                    
                </div>
            </div>
            <div className='imgContainer'>
                <SimpleGallery carID={carID} />
                { car.equipmentDescription ? 
                <div className={`underContantText ${car.photos.length > 0 ? " " : " padding"}`}>
                    <h2 style={{ fontWeight: 600 }}>Popis výbavy</h2>
                    <p style={{whiteSpace: "pre-wrap"}}>
                        <div dangerouslySetInnerHTML={{ __html: car.equipmentDescription }} />
                    </p>
                </div> 
                    : "" }
                
            </div>
        </div>
        </div>

        <div className="contactFormWrapper" style={{
            backgroundImage: "url(" + require('./../../../../images/index.png') + ")"
        }}>
            <h2>Napište nám</h2>
            <ContactForm shadow subject_placeholder={car.brandModel} />
        </div>
    </div>

    let carInvisibleContent = <div className="errorContainer">
        <div>
            <p>Je nám líto, ale vozidlo které hledáte již nemáme v nabídce.</p>
            <p className='emoji'>:(</p>
        </div>
        <a href="#">Zpět na úvodní stranu</a>
    </div>

    return (
        <div className='vehicleDetailContainer'>
            
            <LoadingPage visible={!loading} timeMargin={1.2} />
            <Navbar loading={loading} txtColor="white" />
            { car.visibility ? carVisibleContent : carInvisibleContent }

            <ConcreteFooter />
        </div>
    );
}

export default VehicleDetail;