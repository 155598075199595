import React, {useState} from 'react';
import './Tile.scss'

function Tile(props) {
    
    return (
        <div className='tileContainer'>
            <img loading='lazy' src={props.img} />
        </div>
    );
}

export default Tile;