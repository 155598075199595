import react from 'react'
import './LoadingPage.scss'

function LoadingPage(props) {
    let time = 0.5;
    if (props.timeMargin) {
        time += props.timeMargin
    }
    return <div className="loadingPageContainer" style={{
        transition: props.visible ? `opacity ${time}s` : "",
        opacity: props.visible ? 0 : 1
    }}>
    </div>
}

export default LoadingPage;