import React from 'react';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import OpenningHoursService from '../../generic/services/OpenningHoursService';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const service = new OpenningHoursService();
function OpenningHoursEditor(props) {


    const [list, setList] = useState([])
    const [open, setOpen] = useState(false);
    const [Id, setId] = useState(null);
    const [ShiftStart, setShiftStart] = useState(null);
    const [ShiftEnd, setShiftEnd] = useState(null)
    const [LunchStart, setLunchStart] = useState(null);
    const [lunchEnd, setlunchEnd] = useState(null)
    const [ServiceType, setServiceType] = useState(0);
    const [DayInWeek, setDayInWeek] = useState("")

    useEffect(() => {
        service.GetAll().then(data => { setList(data) })
    }, [])

    function HandleChange(id) {
        let item = {
            "id": Id,
            "shiftStart": ConvertTimeToDaytime(ShiftStart),
            "shiftEnd": ConvertTimeToDaytime(ShiftEnd),
            "lunchStart": ConvertTimeToDaytime(LunchStart),
            "lunchEnd": ConvertTimeToDaytime(lunchEnd),
            "serviceType": ServiceType,
            "dayInWeek": DayInWeek,
        }
        service.Update(item).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })

    }
    function HandleDelete(id) {
        service.Delete(id).then(() => {
            service.GetAll().then(data => { setList(data) });
        })
    }
    function ConvertTimeToDaytime(str) {
        if (str == "") return null;
        let a = new Date(null)
        a.setHours(parseInt(str.split(":")[0]) + 1)
        a.setMinutes(str.split(":")[1])
        return a.toISOString()
    }
    function HandleAddNew() {
        let item = {
            "shiftStart": ConvertTimeToDaytime(ShiftStart),
            "shiftEnd": ConvertTimeToDaytime(ShiftEnd),
            "lunchStart": ConvertTimeToDaytime(LunchStart),
            "lunchEnd": ConvertTimeToDaytime(lunchEnd),
            "serviceType": ServiceType,
            "dayInWeek": DayInWeek,
        }
        service.Create(item).then(() => {

            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })
    }
    function HandleOpen() {
        setOpen(!open);
    }

    return <div style={props.style}>
        <Typography variant='h4'>Otevírací doba</Typography>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Den</TableCell>
                    <TableCell>Začátek</TableCell>
                    <TableCell>Konec</TableCell>
                    <TableCell>Pauza začátek</TableCell>
                    <TableCell>Pauza konec</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.dayInWeek}
                        </TableCell>
                        <TableCell>
                            {item.shiftStart}
                        </TableCell>
                        <TableCell>
                            {item.shiftEnd}
                        </TableCell>
                        <TableCell>
                            {item.lunchStart}
                        </TableCell>
                        <TableCell>
                            {item.lunchEnd}
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#2a60f5' }} onClick={() => {
                                setId(item.id);
                                setShiftStart(item.shiftStart);
                                setShiftEnd(item.shiftEnd)
                                setLunchStart(item.lunchStart);
                                setlunchEnd(item.lunchEnd)
                                setServiceType(item.serviceType)
                                setDayInWeek(item.dayInWeek)
                                HandleOpen();
                            }}>Upravit</Button>
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#f52a2a' }} onClick={() => { HandleDelete(item.id) }}>Smazat</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Button variant="contained" style={{ marginTop: 15, backgroundColor: "#2a60f5" }} onClick={() => {
            setShiftStart("08:00");
            setShiftEnd("16:00")
            setLunchStart("12:00");
            setlunchEnd("14:00")
            setServiceType(0)
            setDayInWeek("Pondělí")
            HandleOpen();
        }}>Přidat položku</Button>

        <Dialog open={open} onClose={HandleOpen}>
            <DialogTitle>{Id == null ? "Vytvořit nový" : "Editace"}</DialogTitle>
            <DialogContent>

                <TextField style={{ marginTop: 15 }} fullWidth variant='standard' InputLabelProps={{ shrink: true }} type={"time"} label="Začátek otevírací doby" value={ShiftStart} onChange={(e) => { setShiftStart(e.target.value) }} />
                <TextField style={{ marginTop: 15 }} fullWidth variant='standard' InputLabelProps={{ shrink: true }} type={"time"} label="Konec otevírací doby" value={ShiftEnd} onChange={(e) => { setShiftEnd(e.target.value) }} /> <br />
                <TextField style={{ marginTop: 15 }} fullWidth variant='standard' InputLabelProps={{ shrink: true }} type={"time"} label="Začátek polední pauzy" value={LunchStart} onChange={(e) => { setLunchStart(e.target.value) }} />
                <TextField style={{ marginTop: 15 }} fullWidth variant='standard' InputLabelProps={{ shrink: true }} type={"time"} label="Konec polední pauzy" value={lunchEnd} onChange={(e) => { setlunchEnd(e.target.value) }} /> <br />
                <TextField style={{ marginTop: 15 }} fullWidth variant='standard' InputLabelProps={{ shrink: true }} label="Den / dny" value={DayInWeek} onChange={(e) => { setDayInWeek(e.target.value) }} />
                <Select
                    style={{ marginTop: 15 }}
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={ServiceType} onChange={e => setServiceType(e.target.value)}
                    variant="outlined"
                >
                    <MenuItem value={0}>Servis</MenuItem>
                    <MenuItem value={1}>Prodejna</MenuItem>
                </Select>


            </DialogContent>
            <DialogActions>
                <Button style={{ color: "#f52a2a" }} onClick={HandleOpen}>Zrušit</Button>
                <Button style={{ color: "#2a60f5" }} onClick={() => {
                    if (Id == null) HandleAddNew()
                    else HandleChange(Id)

                }}>Uložit</Button>
            </DialogActions>
        </Dialog>
    </div>;
}

export default OpenningHoursEditor;