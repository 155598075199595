import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer'
import ContactService from './../../../generic/services/ContactService.js'

const cs = new ContactService();

function ConcreteFooter(props) {
  const [data, setData] = useState([]);
  useEffect(()=>{
    cs.GetAll().then((data)=> {
        setData(data)
    })
  },[])

    let people = []
    data.forEach(l => {
      if (l.isInFooter) {
        people.push({link: "", text: `${l.name} – ${l.subsection}`, styles: {fontWeight: "600"}})
        people.push({link: "", text: l.mobileNumber})
        people.push({link: "", text: l.email})
      }
    })

    return (
        <Footer
              p={props}
              bg="#EDEDED"
              category_txt_color="black"
              subcategory_txt_color="black"
              categories={[
                {text: "Nabídka vozů", sub_categories: [
                  {link: "/homenew#offer", text: "Nové vozy", mainPage: props.mainPage, setShowNew: props.setShowNew, setShowOld: props.setShowOld, newCars: true}, 
                  {link: "/homeold#offer", text: "Ojeté vozy", mainPage: props.mainPage, setShowNew: props.setShowNew, setShowOld: props.setShowOld, oldCars: true}
                ]},
                {text: "O nás", sub_categories: [
                  {link: "gallery/", text: "Galerie"},
                  {link: "https://www.google.com/maps/place/CL+Junior+Auto+Boskovice+s.r.o.+-+Boskovice/@49.4897884,16.6626485,17z/data=!3m1!4b1!4m6!3m5!1s0x4712628d3612fe9b:0xd4378d4047e15f49!8m2!3d49.4897849!4d16.6648372!16s%2Fg%2F1v9l9hw4", text: "Kde nás najdete", new: true},
                  {link: "contact/", text: "Kontakt"},
                  {link: "gdpr/", text: "GDPR"}
                ]},
                {text: "Kontakt", sub_categories: people}
                
              ]}
            />
    )
}

export default ConcreteFooter