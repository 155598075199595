import { Button, Divider, Drawer, Typography } from '@mui/material';
import React from 'react';
import UserService from '../../generic/services/UserService';


const service = new UserService
function CMSDrawer(props) {

  return (
    <div
      style={{ background: "linear-gradient(-180deg, rgba(30,90,240,1) 0%, rgba(80,85,221,1) 100%)", position: "relative", color: "white", width: "250px" }}


    >
      <div style={{ paddingTop: "30px", display: "flex", flexDirection: "column" }}>
        <Typography variant='h4' align='center'>Redakční systém</Typography>
        <Divider style={{ margin: "5px" }} />
        {/* <Button fullWidth style={{ color: "white" }}>Obecné</Button><br />
        <Button fullWidth style={{ color: "white" }}>Vozy na skladě</Button><br />
        <Button fullWidth style={{ color: "white" }}>Nové vozy</Button><br />
        <Divider style={{ margin: "5px", backgroundColor: "white" }} />
        <Button fullWidth style={{ color: "white" }}>Bannery</Button><br /> */}

        <Button style={{ marginTop: "auto" }} fullWidth color='info' variant="contained" onClick={() => {
          service.Logout().then(()=>{
            localStorage.setItem("logged", false);
            window.location.reload();
          })
        }}>Odhlásit se</Button>
      </div>
    </div>
  )
}

export default CMSDrawer;