import React from 'react';
import Button from '@mui/material/Button';
import PriceListService from '../../generic/services/PriceListService';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';


const service = new PriceListService();
function PriceListEditor(props) {


    const [list, setList] = useState([])
    const [open, setOpen] = useState(false);
    const [editName, setEditName] = useState("");
    const [editDesc, setDesc] = useState("");
    const [editPrice, setEditPrice] = useState(0);
    const [editedID, setEditedID] = useState(null)

    useEffect(() => {
        service.GetAll().then(data => { setList(data) })
    }, [])

    function HandleChange(id) {
        let price = editPrice;
        if (price == "") {
            price = 0
        }
        service.Update({ "Id": id, "Name": editName, "Price": price, "Description": editDesc }).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })

    }
    function HandleDelete(id) {
        service.Delete(id).then(() => {
            service.GetAll().then(data => { setList(data) });
        })
    }
    function HandleAddNew() {
        let price = editPrice;
        if (price == "") {
            price = 0
        }
        service.Create({ "Name": editName, "Price": price, "Description": editDesc }).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })
    }
    function HandleOpen() {
        setOpen(!open);
    }
    return <div style={props.style}>
        <Typography variant='h4'>Ceník</Typography>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Název položky</TableCell>
                    <TableCell>Popisek</TableCell>
                    <TableCell>Cena</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.name}
                        </TableCell>
                        <TableCell>
                            {item.description}
                        </TableCell>
                        <TableCell>
                            {item.price}
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#2a60f5' }} onClick={() => {
                                setEditName(item.name)
                                setEditPrice(item.price)
                                setEditedID(item.id);
                                setDesc(item.description)
                                HandleOpen();
                            }}>Upravit</Button>
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#f52a2a' }} onClick={() => { HandleDelete(item.id) }}>Smazat</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Button variant="contained" style={{ marginTop: 15, backgroundColor: "#2a60f5" }} onClick={() => {
            setEditName("")
            setEditPrice(0)
            setEditedID(null)
            setDesc("")
            HandleOpen();
        }}>Přidat položku</Button>

        <Dialog open={open} onClose={HandleOpen}>
            <DialogTitle>{editedID == null ? "Vytvořit nový" : "Editace"}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ marginTop: 10 }}>
                </DialogContentText>
                <TextField style={{ marginRight: "15px", marginBottom: "15px" }} label="Položka" value={editName} onChange={e => setEditName(e.target.value)} />
                <TextField style={{ marginRight: "15px", }} label="Popis" value={editDesc} onChange={e => setDesc(e.target.value)} />
                <TextField label="Cena" value={editPrice} onChange={e => setEditPrice(e.target.value)} />

            </DialogContent>
            <DialogActions>
                <Button style={{ color: "#f52a2a" }} onClick={HandleOpen}>Zrušit</Button>
                <Button style={{ color: "#2a60f5" }} onClick={() => {
                    if (editedID == null) HandleAddNew()
                    else HandleChange(editedID)

                }}>Uložit</Button>
            </DialogActions>
        </Dialog>
    </div>;
}

export default PriceListEditor;