import React, { useEffect } from 'react';
import PhotoAlbum from "react-photo-album";
import Dialog from '@mui/material/Dialog';
import './SimpleGallery.scss'
import CarService from './../../../generic/services/CarService';
import { getImageSize } from 'react-image-size';
import {ReactComponent as Left} from "./../../../../images/left.svg";
import {ReactComponent as Right} from "./../../../../images/right.svg";
const vehService = new CarService()

async function GenerateImages(car) {
   let p = []
   for (let i = 0; i < car.photos.length; i++) {
      let data = await getImageSize("Images/" + car.photos[i].path)
      
      p.push({
         src: "Images/" + car.photos[i].path,
         width: data.width,
         height: data.height,
      })
   }
   return p;
}

function SimpleDialog(props) {

   if (props.activeIndex >= props.images.length) {
      return
   }

   const handleClose = () => {
     props.onClose();
   };
 
   return (
      <Dialog onClose={handleClose} open={props.open} maxWidth="lg" fullWidth>
         <div className='dialog' style={{
            backgroundColor: "red",
            backgroundImage: `url("${ props.images[props.activeIndex].src }")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat" 
         }}>

            <div style={{position: "absolute", display: "flex", justifyContent: "space-between", height: "100%", width: "100%", zIndex: 15}}>
               <div className='moveContainer' onClick={() => props.handler(props.activeIndex - 1)}>
                  <Left className='icon' style={{ width: 13 }} fill="white" />
               </div>
               <div className='moveContainer' onClick={() => props.handler(props.activeIndex + 1)}>
                  <Right className='icon' style={{ width: 13 }} />
               </div>
            </div>
         </div>
      </Dialog>
   );
}

function SimpleGallery (props) {
   
   const [open, setOpen] = React.useState(false);
   const [activeIndex, setActiveIndex] = React.useState(0 );
   const [car, setCar] = React.useState({});
   const [images, setImages] = React.useState([]);


  const handleClickOpen = () => {
   setOpen(true);
   };

 const handleClose = (value) => {
   setOpen(false);
   };

   
   useEffect(()=>{
      vehService.GetById(props.carID)
         .then((data) => {
              setCar(data);
              GenerateImages(data)
              .then((imgs) => {
               setImages(imgs)
              })
         })
      },[])

   const handleIndex = (value) => {
      if (value < images.length && value >= 0) setActiveIndex(value);
   }

   return (
      <div className='galleryContainer'>
         <PhotoAlbum layout="columns" spacing="5" photos={images} onClick={({index}) => {
            handleClickOpen();
            setActiveIndex(index);}} />

               <SimpleDialog
               open={open}
               onClose={handleClose}
               activeIndex={activeIndex}
               fullScreen
               images={images}
               handler={handleIndex}/>
      </div>
   );
}

export default SimpleGallery;