import React from 'react';
import Button from '@mui/material/Button';
import LabelService from '../../generic/services/LabelService';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, MenuItem, Modal, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';
import "./LabelEditor.scss"


const service = new LabelService();
function LabelEditor(props) {


    const [list, setList] = useState([])
    const [open, setOpen] = useState(false);
    const [Id, setId] = useState("");
    const [Key, setKey] = useState("");
    const [Value, setValue] = useState("");





    useEffect(() => {
        service.GetAll().then(data => { setList(data) })
    }, [])

    function HandleChange(id) {
        let item ={
            "Id": Id,
            "Key":Key,
            "Value":Value,

        }
        service.Update(item).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })

    }
    function HandleDelete(id) {
        service.Delete(id).then(() => {
            service.GetAll().then(data => { setList(data) });
        })
    }
    function HandleAddNew() {
        let item ={
            "Key":Key,
            "Value":Value,

        }
        service.Create(item).then(() => {
            HandleOpen();
            service.GetAll().then(data => { setList(data) })
        })
    }
    function HandleOpen() {
        setOpen(!open);
    }
    let input_style = {margin:"5px"}
    return <div style={props.style}>
        <Typography variant='h4'>Texty</Typography>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Identifikator</TableCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.key}
                        </TableCell>
                        <TableCell>
                            <Button style={{ color:'#2a60f5' }} onClick={() => {
                                setId(item.id)
                                setKey(item.key)
                                setValue(item.value)
                                HandleOpen();
                            }}>Upravit</Button>
                        </TableCell>
                        <TableCell>
                            <Button style={{ color:'#f52a2a' }} onClick={() => { HandleDelete(item.id) }}>Smazat</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Button variant="contained" style={{ marginTop: 15, backgroundColor: "#2a60f5" }} onClick={() => {
                setId(null)
                setKey("");
                setValue("");
                HandleOpen();
        }}>Přidat položku</Button>

        <Dialog maxWidth="xl" fullWidth={true} open={open} onClose={HandleOpen}>
            <DialogTitle>{Id == null ? "Vytvořit nový" : "Editace"}</DialogTitle>
            <DialogContent style={{width:"1535px", paddingRight: 35}} >
                <DialogContentText>
                </DialogContentText>
                <TextField style={input_style}  variant='standard' label="Identifikátor" value={Key} onChange={e => setKey(e.target.value)}/> <br />
                {/* <TextField fullWidth style={input_style} variant='standard' label="Text" value={Value} onChange={e => setValue(e.target.value)}/> <br /> */}
                <DefaultEditor value={Value} onChange={e => setValue(e.target.value)} /> <br />

            </DialogContent>
            <DialogActions>
                <Button style={{ color: "#f52a2a" }} onClick={HandleOpen}>Zrušit</Button>
                <Button style={{ color: "#2a60f5" }} onClick={() => {
                    if (Id == null) HandleAddNew()
                    else HandleChange(Id)

                }}>Uložit</Button>
            </DialogActions>
        </Dialog>
    </div>;
}

export default LabelEditor;