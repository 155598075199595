import React, { useState } from 'react';
import './styles.scss';
import { Routes, Route } from "react-router-dom";
import GeneralPage from './components/cms/pages/ExamplePage/GeneralPage';
import HomePage from './components/frontend/pages/HomePage/HomePage';
import ServicesPage from './components/frontend/pages/ServicesPage/ServicesPage';
import ContactPage from './components/frontend/pages/ContactPage/ContactPage';
import VehicleDetail from './components/frontend/pages/VehicleDetail/VehicleDetail';
import GalleryPage from './components/frontend/pages/GalleryPage/GalleryPage';
import GDPRPage from './components/frontend/pages/GDPRPage/GDPRPage';

export default function App () {



    return (
      <React.Fragment>
          <Routes>
            <Route exact path='/' element={<HomePage showNew={true} showOld={true} />}/>
            <Route exact path='/homenew' element={<HomePage showNew={true} showOld={false} />} />
            <Route exact path='/homeold' element={<HomePage showNew={false} showOld={true} />} />
            <Route exact path='/new_vehicles' element={<HomePage  />}/>
            <Route exact path='/used_vehicles' element={<HomePage  />}/>
            <Route exact path='/services' element={<ServicesPage  />}/>
            <Route exact path='/vehicle_detail/:id' element={<VehicleDetail  />}/>
            <Route exact path='/contact' element={<ContactPage  />} />
            <Route exact path='/gallery' element={<GalleryPage  />} />
            <Route exact path='/cms' element={<GeneralPage  />} />
            <Route exact path='/gdpr' element={<GDPRPage  />} />
          </Routes>
      </React.Fragment>
    )
}

