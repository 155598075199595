import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import BannerService from '../../generic/services/BannerService';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { useDropzone } from 'react-dropzone';


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 5,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#555555',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
function StyledDropzone(props) {
    const [filePreviews, setFilePreviews] = useState([]);
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'image/*': [] },
        multiple: false,
        onDrop: acceptedFiles => {
            setFilePreviews(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    props.SetImageFile(acceptedFiles)
    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Sem přetáhněte obrázky, které si přejete nahrát.</p>
            </div>
            {filePreviews.length > 0 ? <img alt="preview_image" style={{ maxWidth: "400px" }} src={filePreviews[0].preview} /> : ""}
        </div>
    );
}


const service = new BannerService();

function BannerEditor(props) {
    const [list, setList] = useState([])
    const [open, setOpen] = useState(false);
    const [eCategory, seteCategory] = useState(0);
    const [categoryList, setCategoryList] = useState([])
    const [loading, setLoading] = useState(true)
    let image = null;
    useEffect(() => {
        GetAll();

    }, [])
    function GetAll() {
        setLoading(true)
        service.GetAll().then(data => {
            setList(data);
            let category_list = []
            for (let item of data) {
                category_list.push(item.location)
            }
            setCategoryList(category_list)
            setLoading(false)
        })
    }
    function HandleOpen() {
        if (loading) return;
        setOpen(!open);
    }

    function HandeUpload() {
        setLoading(true)
        if (image == null || image.length == 0) return;
        let item = {
            "visibility": true,
            "url": "url",
            "location": eCategory
        }
        service.Create(item, image[0]).then(() => {
            setLoading(false)
            GetAll();
            HandleOpen();
        });
    }
    function HandleDelete(id) {
        service.Delete(id).then(() => {
            GetAll();
        })
    }
    function HandeChange(index, val) {
        let category_list = []
        for (let item of list) {
            category_list.push(item.location)
        }
        category_list[index] = val;
        setCategoryList(category_list)

        let item = {
            "id": list[index].id,
            "visibility": true,
            "url": "url",
            "location": val
        }
        service.Update(item).then(() => {
            service.GetAll().then(data => {
                setList(data);
                let category_list = []
                for (let item of data) {
                    category_list.push(item.location)
                }
                setCategoryList(category_list)
            })
        });
    }
    return <div style={props.style}>
        <Typography variant='h4'>Obrázky</Typography>
        <Button style={{ marginTop: 15, backgroundColor: "#2a60f5" }} variant='contained' onClick={() => { HandleOpen() }}>Přidat nový obrázek</Button>
        <p></p>
        <h4 style={{ marginBottom: 15 }}>Galerie</h4>
        {loading ? <CircularProgress /> : ""}
        <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>
            {list.map((item, index) => {
                if (item.location !== 0) return ""
                return <div key={index}>
                    <Card style={{ marginRight: 20, marginBottom: 20 }}>
                        {item.photo != null ?
                            <CardMedia
                                component="img"
                                height="194"
                                image={"Images/" + item.photo.path}
                                alt="Paella dish"
                                style={{ width: "200px", height: "120px" }}
                            />
                            : ""}
                        <CardActions>
                            <Select
                                variant="outlined"
                                value={categoryList[index]}
                                onChange={(e) => { HandeChange(index, e.target.value) }}
                            >
                                <MenuItem value={0}>Galerie</MenuItem>
                                <MenuItem value={1}>Banner</MenuItem>
                            </Select>
                            <Button style={{ color: "#f52a2a", marginLeft: 13 }} onClick={() => {
                                HandleDelete(item.id)
                            }}>Smazat</Button>
                        </CardActions>
                    </Card>
                </div>
            })}
        </div>
        <h4 style={{ marginBottom: 15 }}>Úvodní strana – bannery</h4>
        <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>
            {list.map((item, index) => {
                if (item.location !== 1) return ""
                return <div key={index}>
                    <Card style={{ marginRight: 20, marginBottom: 20 }}>
                        {item.photo != null ?
                            <CardMedia
                                component="img"
                                height="194"
                                image={"Images/" + item.photo.path}
                                alt="Paella dish"
                                style={{ width: "200px", height: "120px" }}
                            />
                            : ""}
                        <CardActions>
                            <Select
                                variant="outlined"
                                value={categoryList[index]}
                                onChange={(e) => { HandeChange(index, e.target.value) }}
                            >
                                <MenuItem value={0}>Galerie</MenuItem>
                                <MenuItem value={1}>Banner</MenuItem>
                            </Select>
                            <Button style={{ color: "#f52a2a", marginLeft: 9 }} onClick={() => {
                                HandleDelete(item.id)
                            }}>Smazat</Button>
                        </CardActions>
                    </Card>
                </div>
            })}
        </div>


        <Dialog open={open} onClose={HandleOpen}>
            <DialogTitle>Přidat nový obrázek</DialogTitle>
            <DialogContent>
                <p>Sem nahrajte obrázky:</p>
                <StyledDropzone SetImageFile={(file) => { image = file; }} />
                <p style={{ marginTop: 15 }}>Zvolte kategorii:</p>
                <Select
                    value={eCategory}
                    onChange={e => { seteCategory(e.target.value) }}>
                    <MenuItem value={0}>Galerie</MenuItem>
                    <MenuItem value={1}>Banner</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                {!loading ? <div>
                    <Button onClick={HandleOpen}>Zrušit</Button>
                    <Button onClick={() => {
                        HandeUpload()

                    }}>Uložit</Button></div> : ""}
                {loading ? <CircularProgress /> : ""}
            </DialogActions>
        </Dialog>
    </div>
}

export default BannerEditor;