import React, {useState} from 'react';
import './Navbar.scss'
import {ReactComponent as MenuLogo} from "./../../../../images/bars-solid.svg";
import {ReactComponent as FBLogo} from "./../../../../images/square-facebook.svg";

function Navbar(props) {
    const [nav, setNav] = useState(false);
    let styles = {
        color: !! props.txtColor ? props.txtColor : 'white'
    }

    return (
        <div style={{ visibility: props.loading? "hidden": "visible" }}>
            <div className={ `navbarContainer ${ nav ? "active" : "" }` }>
                <div className='logo'>
                    <a href="https://www.citroen.cz" target="_blank"><img loading='lazy' src={require("./../../../../images/logo.png")} alt="citroen logo" /></a>
                </div>

                

                <div className="links">
                    <a href="/" className='homeLogo' style={styles}><img loading='lazy' src={require('./../../../../images/Vector.png')} /></a>

                    <a href={ props.mainPage == true ? "#offer" : "/homenew#offer"} onClick={() => {
                        if (props.mainPage) {
                            props.setShowNew(true);
                            props.setShowOld(false);
                        } 
                    }} style={styles}><span>Nabídka nových vozů</span></a>
                    <a href={ props.mainPage == true ? "#offer" : "/homeold#offer"}  onClick={() => {
                        if (props.mainPage) {
                            props.setShowNew(false);
                            props.setShowOld(true);
                        } 
                    }}  style={styles}><span>Nabídka ojetých vozů</span></a>

                    <a href="services/" style={styles}><span>Servis a prodej</span></a>
                    <a href="gallery/" style={styles}><span>Galerie</span></a>
                    <a href="contact/" style={styles}><span>Kontakt</span></a>
                </div>

                <div className='socialMedia'>
                    <a href="https://www.facebook.com/" target="_blank"><FBLogo fill='white' /></a>
                    
                </div>

                <span className='navbarToggle' onClick={ () => setNav(!nav) }>
                    <MenuLogo fill='white' />
                </span>
                
            </div>

            <div style={{ zIndex: nav ? 9 : -1 }} className={`closeDiv`} onClick={ () => 
                {
                    if (nav) {
                        setNav(!nav)
                    }
                }
            }>
            </div>
        </div>
    );
}

export default Navbar;