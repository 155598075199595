import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import PriceListService from '../../generic/services/PriceListService';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, Select, Slide, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import CarService from '../../generic/services/CarService';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Trash } from "./../../../images/xmark-solid.svg";
import { TransitionProps } from '@mui/material/transitions';
import { DefaultEditor } from 'react-simple-wysiwyg';
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 5,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#555555',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
function StyledDropzone(props) {
    const [filePreviews, setFilePreviews] = useState([]);
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'image/*': [] },
        onDrop: acceptedFiles => {
            setFilePreviews(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    }
    );

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    props.FilesCallback(acceptedFiles);
    const thumbs = filePreviews.map(file => (
        <div key={file.name}>
            <img
                style={{ maxHeight: "100px", margin: "3px" }}
                src={file.preview}
                alt={file.path}
            />
        </div>
    ));
    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Sem přetáhněte obrázky, které si přejete nahrát.</p>
            </div>
            {thumbs.length != 0 ? <p style={{ color: "#ffffff", fontWeight: "bold" }}>Fotografie připravené k nahrátí:</p> : ""}
            <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {thumbs}
            </div>
        </div>
    );
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});



const service = new CarService();
const image_path = "Images"
function CarListEditor(props) {
    const imagePreviewStyles = {
        // width: "150px",
        height: "150px",
        borderRadius: "5px"
    }

    const [list, setList] = useState([])
    const [open, setOpen] = useState(false);

    const [editedID, setEditedID] = useState(null)
    const [eBranModel, seteBrandModel] = useState("")
    const [eIsNew, seteIsNew] = useState(false)
    const [eTransmission, seteTransmission] = useState("")
    const [eSpeedometer, seteSpeedometer] = useState("")
    const [eCategory, seteCategory] = useState("")
    const [EngineContent, setEngineContent] = useState(0)
    const [Power, setPower] = useState("")
    const [Color, setColor] = useState("")
    const [Fuel, setFuel] = useState("")
    const [TypeCar, setTypeCar] = useState("")
    const [IsDeductionDPH, setIsDeductionDPH] = useState(false)
    const [DoorCount, setDoorCount] = useState("")
    const [SeatCount, setSeatCount] = useState("")
    const [State, setState] = useState("")
    const [FuelConsumption, setFuelConsumption] = useState("")
    const [Emission, setEmission] = useState("")
    const [Description, setDescription] = useState("")
    const [EquipmentDescription, setEquipmentDescription] = useState("")
    const [DescriptionNote, setDescriptionNote] = useState("")
    const [Price, setPrice] = useState("")
    const [DiscountPrice, setDiscountPrice] = useState("")
    const [Visibility, setVisibility] = useState(false)
    const [Torque, setTorque] = useState(0)
    const [MainImageId, setMainImageId] = useState(0)

    const [eImageList, seteImageList] = useState([])
    const [loading, setLoading] = useState(true)

    const [eLCDV, seteLCDV] = useState("")
    const [StrokeVolume, setStrokeVolume] = useState(0)
    const [TrunkVolume, setTrunkVolume] = useState(0)
    const [Weight, setWeight] = useState(0)
    const [MaxSpeed, setMaxSpeed] = useState(0)
    const [ZeroToHundered, setZeroToHundered] = useState(0)
    const [eECE, seteECE] = useState(0)
    const [eEUDC, seteEUDC] = useState(0)
    const [MixxedTraffic, setMixxedTraffic] = useState(0)
    const [TankVolume, setTankVolume] = useState(0)
    const [Lenght, setLenght] = useState(0)
    const [Width, setWidth] = useState(0)
    const [Height, setHeight] = useState(0)
    const [Upholstery, setUpholstery] = useState("")

    let drop_zone_files = []
    const input_style = {
        marginTop: "10px",
        marginBottom: "5px"
    }
    useEffect(() => {
        service.GetAll().then(data => {
            setList(data)
            setLoading(false)
        })
    }, [])
    function SetFiles(files) {
        drop_zone_files = files;
    }
    function HandleChange(id) {
        let item = {
            "Id": id,
            "BrandModel": eBranModel,
            "IsNewCar": eIsNew,
            "Transmission": eTransmission,
            "Speedometer": eSpeedometer,
            "Category": eCategory,
            "EngineContent": parseFloat(EngineContent),
            "Power": Power,
            "Color": Color,
            "Fuel": Fuel,
            "TypeCar": TypeCar,
            "IsDeductionDPH": IsDeductionDPH,
            "DoorCount": DoorCount,
            "SeatCount": SeatCount,
            "State": State,
            "Torque": parseFloat(Torque),
            "FuelConsumption": parseFloat(FuelConsumption),
            "Emission": Emission,
            "Description": Description,
            "EquipmentDescription": EquipmentDescription,
            "DescriptionNote": DescriptionNote,
            "Price": Price,
            "DiscountPrice": DiscountPrice,
            "Visibility": Visibility,
            "MainPhotoId": MainImageId,

            "lcdv": eLCDV,
            "StrokeVolume": StrokeVolume,
            "TrunkVolume": TrunkVolume,
            "Weight": Weight,
            "MaxSpeed": MaxSpeed,
            "ZeroToHundered": ZeroToHundered,
            "ece": eECE,
            "eudc": eEUDC,
            "MixxedTraffic": MixxedTraffic,
            "TankVolume": TankVolume,
            "Lenght": Lenght,
            "Width": Width,
            "Height": Height,
            "Upholstery": Upholstery,

        }
        setLoading(true);
        service.Update(item).then(() => {
            for (let i = 0; i < drop_zone_files.length; i++) {
                service.UploadImage(item.Id, drop_zone_files[i])
            }
            service.GetAll().then(data => {
                setList(data);
                setLoading(false);
                HandleOpen();
            })
        })

    }
    function LoadImageSet(id) {
        service.GetImages(id).then(data => {
            seteImageList(data)
        });
    }
    function HandleDelete(id) {
        service.Delete(id).then(() => {
            service.GetAll().then(data => { setList(data) });
        })
    }
    function HandleAddNew() {
        let item = {
            "BrandModel": eBranModel,
            "IsNewCar": eIsNew,
            "Transmission": eTransmission,
            "Speedometer": eSpeedometer,
            "Category": eCategory,
            "EngineContent": parseFloat(EngineContent),
            "Power": Power,
            "Color": Color,
            "Fuel": Fuel,
            "TypeCar": TypeCar,
            "IsDeductionDPH": IsDeductionDPH,
            "DoorCount": DoorCount,
            "SeatCount": SeatCount,
            "State": State,
            "Torque": parseFloat(Torque),
            "FuelConsumption": parseFloat(FuelConsumption),
            "Emission": Emission,
            "Description": Description,
            "EquipmentDescription": EquipmentDescription,
            "DescriptionNote": DescriptionNote,
            "Price": Price,
            "DiscountPrice": DiscountPrice,
            "Visibility": Visibility,

            "lcdv": eLCDV,
            "StrokeVolume": StrokeVolume,
            "TrunkVolume": TrunkVolume,
            "Weight": Weight,
            "MaxSpeed": MaxSpeed,
            "ZeroToHundered": ZeroToHundered,
            "ece": eECE,
            "eudc": eEUDC,
            "MixxedTraffic": MixxedTraffic,
            "TankVolume": TankVolume,
            "Lenght": Lenght,
            "Width": Width,
            "Height": Height,
            "Upholstery": Upholstery,
        }

        setLoading(true)
        service.Create(item).then((result) => {
            for (let i = 0; i < drop_zone_files.length; i++) {
                service.UploadImage(result.id, drop_zone_files[i])
            }
            service.GetAll().then(data => {
                setList(data);
                setLoading(false);
                HandleOpen();
            })
        })
    }
    function HandleDeleteImage(image_id) {

        service.DeleteImage(image_id).then(() => {
            service.GetAll().then(data => { setList(data) });
            LoadImageSet(editedID)
        })
    }
    function HandleOpen() {
        setOpen(!open);
    }

    function GetInt(val, setter) {
        if (val.match(/^\d*$/)) {
            setter(val)
        }
    }
    function GetFloat(val, setter) {
        let input = val;

        if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/))
            setter(input)
    }
    const [html, setHtml] = React.useState('my <b>HTML</b>');
    return <div style={props.style}>
        <Typography variant='h4'>Vozidla</Typography>
        {/* <p>Vozidla nahraná v systému</p> */}
        {loading ? <CircularProgress /> : ""}
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell>Model</TableCell>
                    <TableCell>Cena</TableCell>
                    <TableCell>Nájezd</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {item.brandModel}
                        </TableCell>
                        <TableCell>
                            {item.price}
                        </TableCell>
                        <TableCell>
                            {item.speedometer}
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: "#2a60f5" }} onClick={() => {
                                service.GetById(item.id).then((item) => {
                                    seteBrandModel(item.brandModel)
                                    setEditedID(item.id);
                                    seteSpeedometer(item.speedometer)
                                    seteCategory(item.category)
                                    seteTransmission(item.transmission)
                                    seteIsNew(item.isNewCar)
                                    seteImageList(item.photos)

                                    setEngineContent(item.engineContent)
                                    setPower(item.power)
                                    setColor(item.color)
                                    setFuel(item.fuel)
                                    setTypeCar(item.typeCar)
                                    setIsDeductionDPH(item.isDeductionDPH)
                                    setDoorCount(item.doorCount)
                                    setSeatCount(item.seatCount)
                                    setState(item.state)
                                    setFuelConsumption(item.fuelConsumption)
                                    setEmission(item.emission)
                                    setDescription(item.description)
                                    setEquipmentDescription(item.equipmentDescription)
                                    setDescriptionNote(item.descriptionNote)
                                    setPrice(item.price)
                                    setDiscountPrice(item.discountPrice)
                                    setVisibility(item.visibility)
                                    setTorque(item.torque)
                                    setMainImageId(item.mainPhotoId)

                                    seteLCDV(item.lcdv)
                                    setStrokeVolume(item.strokeVolume)
                                    setTrunkVolume(item.trunkVolume)
                                    setWeight(item.weight)
                                    setMaxSpeed(item.maxSpeed)
                                    setZeroToHundered(item.zeroToHundered)
                                    seteECE(item.ece)
                                    seteEUDC(item.eudc)
                                    setMixxedTraffic(item.mixxedTraffic)
                                    setTankVolume(item.tankVolume)
                                    setLenght(item.lenght)
                                    setWidth(item.width)
                                    setHeight(item.height)
                                    setUpholstery(item.upholstery)


                                    HandleOpen();

                                })
                            }}>Upravit</Button>
                        </TableCell>
                        <TableCell>
                            <Button style={{ color: '#f52a2a' }} onClick={() => { HandleDelete(item.id) }}>Smazat</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        <Button style={{ marginTop: 15, backgroundColor: "#2a60f5" }} variant="contained" onClick={() => {
            setEditedID(null)
            seteBrandModel("")
            seteSpeedometer(1000)
            seteCategory(0)
            seteImageList([])
            seteTransmission("")
            seteIsNew(false)
            HandleOpen();

            setEngineContent(0.0)
            setPower(0)
            setColor("")
            setFuel("")
            setTypeCar("")
            setIsDeductionDPH(false)
            setDoorCount(5)
            setSeatCount(5)
            setState("")
            setFuelConsumption(0.0)
            setEmission(0.0)
            setDescription("")
            setEquipmentDescription("")
            setDescriptionNote("")
            setPrice(0)
            setDiscountPrice(0)
            setVisibility(false)
            setTorque(0)
            setMainImageId(null)


            seteLCDV("")
            setStrokeVolume(0)
            setTrunkVolume(0)
            setWeight(0)
            setMaxSpeed(0)
            setZeroToHundered(0)
            seteECE(0)
            seteEUDC(0)
            setMixxedTraffic(0)
            setTankVolume(0)
            setLenght(0)
            setWidth(0)
            setHeight(0)
            setUpholstery("")

        }}>Přidat vozidlo</Button>

        <Dialog TransitionComponent={Transition} open={open} onClose={HandleOpen} fullScreen={true} PaperProps={{ style: { backgroundImage: "url(" + require('./../../../images/index.png') + ")" } }}>
            <DialogActions style={{ background: "linear-gradient(90deg, rgba(222,51,51,1) 0%, rgba(44,60,209,1) 100%)", display: "flex", justifyContent: "center" }}>
                <Button disabled={loading} style={{ color: loading ? "darkgrey" : "white" }} onClick={HandleOpen} >Zrušit</Button>
                <Button disabled={loading} style={{ backgroundColor: loading ? "darkgrey" : "white", color: "black" }} onClick={() => {
                    if (editedID == null) HandleAddNew()
                    else HandleChange(editedID)

                }} variant='contained' color='success'>Uložit</Button>
                {loading ? <CircularProgress /> : ""}
            </DialogActions>

            <DialogContent style={{ padding: "30px" }}>
                <div style={{ fontSize: "35px", fontWeight: 500 }}> {editedID == null ? "Vytvořit nový" : "Editace"}</div>


                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <div style={{ maxWidth: "600px", backgroundColor: "#ffffff", border: "1px solid lightgray", padding: "20px", borderRadius: "10px", minWidth: "400px" }}>
                            <h4>Informace</h4>
                            <TextField fullWidth style={input_style} variant='outlined' label="Model" value={eBranModel} onChange={e => seteBrandModel(e.target.value)} /> <br />
                            <FormGroup>
                                <FormControlLabel control={<Switch label="setVisibility" checked={Visibility} onChange={e => setVisibility(e.target.checked)} />} label="Zveřejněné" />
                            </FormGroup>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={eCategory} onChange={e => seteCategory(e.target.value)}
                                variant="outlined"
                            >
                                <MenuItem value={0}>Osobní auto</MenuItem>
                                <MenuItem value={1}>Užitkové</MenuItem>
                            </Select>
                            <TextField minRows={2} multiline fullWidth style={input_style} variant='outlined' label="Popis krátky" value={Description} onChange={e => setDescription(e.target.value)} /> <br />
                            <h5 style={{ marginTop: "10px" }}>Detailní popis</h5>
                            <DefaultEditor value={DescriptionNote} onChange={e => setDescriptionNote(e.target.value)} /> <br />
                            <h5 tyle={{ marginTop: "10px" }}>Popis výbavy</h5>
                            <DefaultEditor value={EquipmentDescription} onChange={e => setEquipmentDescription(e.target.value)} /> <br />
                        </div>

                    </div>
                    <div style={{}}>
                        <div style={{ backgroundColor: "#ffffff", border: "1px solid lightgray", padding: "20px", borderRadius: "10px", width: "350px", marginRight: "15px", marginLeft: "15px" }}>


                            <br />
                            <h6>Obecné</h6>
                            <TextField fullWidth style={input_style} variant='standard' label="Převodovka" value={eTransmission} onChange={e => seteTransmission(e.target.value)} placeholder="Manual" /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Stav" value={State} onChange={e => setState(e.target.value)} placeholder="Nové" /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Tachometr" value={eSpeedometer} onChange={e => { GetInt(e.target.value, seteSpeedometer) }} InputProps={{
                                endAdornment: <InputAdornment position="start">km</InputAdornment>,
                            }} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Barva" value={Color} onChange={e => setColor(e.target.value)} placeholder="Červená" /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Karoserie" value={TypeCar} onChange={e => setTypeCar(e.target.value)} placeholder="Combi" /> <br />
                            {/* <TextField fullWidth style={input_style} variant='standard' label="Odpis DPH" value={IsDeductionDPH} onChange={e => setIsDeductionDPH(e.target.value)} /> <br /> */}
                            <TextField fullWidth style={input_style} variant='standard' label="Počet dveří" value={DoorCount} onChange={e => { GetInt(e.target.value, setDoorCount) }} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Počet sedadel" value={SeatCount} onChange={e => { GetInt(e.target.value, setSeatCount) }} /> <br />
                            <br />
                            <h6>Motor</h6>
                            <TextField fullWidth style={input_style} variant='standard' label="Palivo" value={Fuel} onChange={e => setFuel(e.target.value)} placeholder="Nafta" /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Objem motoru" value={EngineContent} onChange={e => GetFloat(e.target.value, setEngineContent)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Výkon" value={Power} onChange={e => GetInt(e.target.value, setPower)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Spotřeba" value={FuelConsumption} onChange={e => GetFloat(e.target.value, setFuelConsumption)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Max. točivý moment" value={Torque} onChange={e => GetFloat(e.target.value, setTorque)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Emise" value={Emission} onChange={e => GetInt(e.target.value, setEmission)} /> <br />
                            <br />
                            <h6>Cena</h6>
                            <TextField fullWidth style={input_style} variant='standard' label="Cena" value={Price} onChange={e => GetInt(e.target.value, setPrice)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Cena bez DPH" value={DiscountPrice} onChange={e => GetInt(e.target.value, setDiscountPrice)} /> <br />
                            <br />
                            <InputLabel id="demo-simple-select-label">Kategorie</InputLabel>

                            <FormGroup>
                                <FormControlLabel control={<Switch label="IsNew" checked={eIsNew} onChange={e => seteIsNew(e.target.checked)} />} label="Nové auto" />
                            </FormGroup>
                            {/* <FormGroup>
                                <FormControlLabel control={<Switch label="IsDeductionDPH" checked={IsDeductionDPH} onChange={e => setIsDeductionDPH(e.target.checked)} />} label="Odpočet DPH" />
                            </FormGroup> */}

                            <br />
                            <h5>Technická charakteristika</h5>
                            <TextField fullWidth style={input_style} variant='standard' label="eLCDV" value={eLCDV} onChange={e => seteLCDV(e.target.value)} /> <br />
                            <br /><h6>Typ</h6>
                            <TextField fullWidth style={input_style} variant='standard' label="Zdvihový objem v cm3" value={StrokeVolume} onChange={e => GetInt(e.target.value, setStrokeVolume)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Užitečná hmotnost" value={Weight} onChange={e => GetInt(e.target.value, setWeight)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Maximální rychlost" value={MaxSpeed} onChange={e => GetInt(e.target.value, setMaxSpeed)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Z 0 na 100 v s" value={ZeroToHundered} onChange={e => GetInt(e.target.value, setZeroToHundered)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Mětstský provoz ECE" value={eECE} onChange={e => GetInt(e.target.value, seteECE)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Mimoměstský provoz EUDC" value={eEUDC} onChange={e => GetInt(e.target.value, seteEUDC)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Smíšený provoz" value={MixxedTraffic} onChange={e => GetInt(e.target.value, setMixxedTraffic)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Čalounění" value={Upholstery} onChange={e => setUpholstery(e.target.value)} /> <br />
                            <br /><h6>Rozměry</h6>
                            <TextField fullWidth style={input_style} variant='standard' label="Objem zavazadlového prostoru v l" value={TrunkVolume} onChange={e => GetInt(e.target.value, setTrunkVolume)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Objem nádrže " value={TankVolume} onChange={e => GetInt(e.target.value, setTankVolume)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Délka" value={Lenght} onChange={e => GetInt(e.target.value, setLenght)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Šířka" value={Width} onChange={e => GetInt(e.target.value, setWidth)} /> <br />
                            <TextField fullWidth style={input_style} variant='standard' label="Výška" value={Height} onChange={e => GetInt(e.target.value, setHeight)} /> <br />


                        </div>
                    </div>
                    <div>

                        <StyledDropzone FilesCallback={
                            (files) => {
                                drop_zone_files = files;
                            }} />
                        <h4 style={{ color: "#222222", width: "100%", marginTop: 25 }}>Nahrané fotografie</h4>
                        <p>Hlavní fotografii (bude se zobrazovat na úvodní stránce) vyberete kliknutím.</p>
                        <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>
                            {eImageList.map((item, index) => {
                                return <div key={index} style={{ position: "relative", margin: "5px", marginTop: "15px" }}>
                                    <img onClick={() => { setMainImageId(item.id) }}
                                        loading="lazy" alt={image_path}
                                        style={{ ...imagePreviewStyles, outline: item.id == MainImageId ? "3px solid #2a60f5" : '0.5px solid lightgray' }}
                                        src={image_path + "/" + item.path} />

                                    <br />

                                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                                        <Trash style={{ width: 13, marginRight: 7 }} fill="#f52a2a" onClick={() => {
                                            HandleDeleteImage(item.id)
                                        }} />
                                    </div>

                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </DialogContent>


        </Dialog>
    </div >;
}

export default CarListEditor;