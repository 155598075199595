import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import LandingStrip from '../../components/LandingStrip/LandingStrip'
import PhotoGallery from './../../components/PhotoGallery/PhotoGallery'
import ConcreteFooter from './../../components/ConcreteFooter/ConcreteFooter'
import "./GalleryPage.scss"

function GalleryPage() {
    return (
        <div className="galleryPageContainer">
            <Navbar />
            <LandingStrip
                bgImg={require('./../../../../images/index10.png')}
                heading="GALERIE"
                headingColor="black"
                fontWeight="700"
                // bgColor="black"
            />

            <PhotoGallery />
            <ConcreteFooter />
        </div>
    )
}

export default GalleryPage